import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/images/default-avatar.avif'


const _hoisted_1 = { class: "main-employee-box" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  src: _imports_0,
  class: "employee-avatar"
}
const _hoisted_4 = {
  key: 2,
  class: "custom-modal"
}
const _hoisted_5 = { class: "inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_CVTemplate1 = _resolveComponent("CVTemplate1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.employee.PhotoUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.employee.PhotoUrl,
          class: "employee-avatar"
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("img", _hoisted_3)),
    _createElementVNode("span", null, _toDisplayString(_ctx.capitalize(_ctx.employee.FirstName)) + " " + _toDisplayString(_ctx.capitalize(_ctx.employee.LastName)), 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.getTitle(_ctx.employee.JobTitle)), 1),
    _createVNode(_component_v_btn, { onClick: _ctx.showTMDetails }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode("View Details")
      ])),
      _: 1
    }, 8, ["onClick"]),
    (_ctx.showDetails)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "content",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDetails = false))
            }, [
              _createVNode(_component_CVTemplate1, {
                employeeId: _ctx.employee.Id ?? '',
                skills: _ctx.skills,
                details: _ctx.details,
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"]))
              }, null, 8, ["employeeId", "skills", "details"])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}