<template>
  <v-dialog v-model="showActionDialog" persistent max-width="800px">
    <v-form ref="mainForm">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title>
          <div class="card-title">
            <div class="card-title-left">
              {{ commitmentAction.DisplayName }}
            </div>
            <div class="card-title-right"></div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <DateInputField
                label="Effective Date"
                :rules="[rules.required]"
                first-day-of-week="1"
                v-model="selectedEffectiveDate"
                hide-actions
                placeholder="Effective Date"
                prepend-icon=""
                :min="getMinDate"
                :max="getMaxDate"
              />
            </v-col>
          </v-row>
          <VirtualDesktopAddon
            :showEnableCheckBox="false"
            :virtualDesktopSpec="virtualDesktopSpec"
            :diskValues="diskValues"
            :cpuValues="cpuValues"
            :ramValues="ramValues"
            v-if="
              commitmentAction.Type == actionsEnum.CustomerAddVirtualDesktop ||
              commitmentAction.Type == actionsEnum.CustomerModifyVirtualDesktop
            "
          />
          <v-row
            v-if="
              commitmentAction.Type ==
              actionsEnum.CustomerTerminateVirtualDesktop
            "
          >
            <v-col cols="12">
              <v-checkbox
                :rules="[rules.required]"
                label="Are you sure you want to terminate this virtual desktop?"
              />
            </v-col>
          </v-row>

          <v-row
            v-if="commitmentAction.Type == actionsEnum.CustomerExtendContract"
          >
            <v-col cols="6">
              <DateInputField
                label="Extend To Date"
                :rules="[rules.required]"
                first-day-of-week="1"
                v-model="extendContractDate"
                hide-actions
                placeholder="Extend To Date"
                prepend-icon=""
                :year="getExtendContractMinDate.getFullYear()"
                :month="getExtendContractMinDate.getMonth()"
                :min="getExtendContractMinDate.toDateString()"
              />
            </v-col>
          </v-row>
          <div
            v-if="
              commitmentAction.Type == actionsEnum.CustomerTerminateContract
            "
          >
            <div v-if="terminateContract.HasVm">
              <v-row>
                <v-col cols="12">
                  <p v-if="terminateContract.RelatedCommitments.length == 0">
                    This Engagement, has a Virtual Desktop Instance. the
                    instance will be decommission with this termination
                  </p>
                  <p v-else>
                    This Engagement, has a Virtual Desktop Instance. You can
                    choose to move the subscription to another engagement or
                    decommission the instance.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-radio-group
                    v-model="terminateContract.VdState"
                    :rules="[rules.required]"
                  >
                    <v-radio
                      label="Decommission Virtual Desktop on the Effective Date"
                      value="Decommission"
                    />
                    <v-radio
                      v-if="terminateContract.RelatedCommitments.length > 0"
                      label="Transfer Virtual Desktop to another Engagement contract"
                      value="Transfer"
                    />
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <SelectField
                    v-if="terminateContract.VdState == 'Transfer'"
                    label="Transfer To Contract"
                    :rules="[rules.required]"
                    v-model="terminateContract.SelectedTransferCommitmentId"
                    placeholder="Transfer To Contract"
                    :items="terminateContract.RelatedCommitments"
                    item-title="Name"
                    item-value="Id"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="primary"
            variant="elevated"
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="primary"
            variant="elevated"
            @click="applyAction"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import {
    ClientCommitmentApi,
    CommitmentActionDetails,
    CommitmentActionItem,
    CommitmentActionModel,
    CommitmentActionVirtualDesktopDetails,
    CommitmentActionWithAltItemTypeEnum,
  } from "shared-components/src/services/openApi/api"
  import store from "@/store"
  import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue"
  import rules from "shared-components/src/utils/validations"
  import moment from "moment"
  import Utils from "shared-components/src/utils/Utils"
  import SelectField from "shared-components/src/components/Fields/SelectField.vue"

  export default defineComponent({
    components: {
      VirtualDesktopAddon,
    },
    props: ["commitmentAction", "commitment"],
    data() {
      return {
        rules,
        ramValues: {
          0: "8",
          1: "16",
          2: "24",
          3: "32",
        },
        cpuValues: {
          0: "2",
          1: "4",
          2: "6",
        },
        diskValues: {
          0: "128",
          1: "256",
          2: "384",
          3: "512",
        },
        virtualDesktopSpec: {
          AdditionalSecurityBL: false,
          AdditionalSecurityNG: false,
          Cpu: 0,
          Disk: 0,
          Level: null as string | null,
          Location: null as string | null,
          OperatingSystem: null as string | null,
          Policy: null as string | null,
          Ram: 0,
        },
        terminateContract: {
          HasVm: false,
          RelatedCommitments: [],
          SelectedTransferCommitmentId: null as string | null,
          VdState: "",
        },
        extendContractDate: null as Date | null,
        loading: false,
        showActionDialog: true,
        selectedEffectiveDate: null as Date | null,
        actionsEnum: CommitmentActionWithAltItemTypeEnum,
      }
    },
    async created() {},
    mounted() {
      if (
        this.commitmentAction.Type ==
          this.actionsEnum.CustomerModifyVirtualDesktop &&
        this.commitmentAction.VirtualDesktopSpec
      ) {
        this.virtualDesktopSpec = this.commitmentAction.VirtualDesktopSpec
        this.virtualDesktopSpec.Cpu = Number(
          Object.keys(this.cpuValues).find(
            (key) =>
              (this.cpuValues as any)[key] ==
              this.commitmentAction.VirtualDesktopSpec.Cpu
          )
        )
        this.virtualDesktopSpec.Ram = Number(
          Object.keys(this.ramValues).find(
            (key) =>
              (this.ramValues as any)[key] ==
              this.commitmentAction.VirtualDesktopSpec.Ram
          )
        )
        this.virtualDesktopSpec.Disk = Number(
          Object.keys(this.diskValues).find(
            (key) =>
              (this.diskValues as any)[key] ==
              this.commitmentAction.VirtualDesktopSpec.Disk
          )
        )
      }
      if (
        this.commitmentAction.Type == this.actionsEnum.CustomerTerminateContract
      ) {
        this.terminateContract.HasVm = this.commitmentAction.HasVm
        this.terminateContract.RelatedCommitments =
          this.commitmentAction.RelatedCommitments
      }
    },
    methods: {
      closeDialog() {
        this.$emit("OnClose")
      },
      async applyAction() {
        const isValid = await (this.$refs.mainForm as any).validate()
        if (isValid.valid) {
          try {
            this.loading = true
            const requestModel = {
              ActionItems: [],
              CommitmentId: this.commitment.Id,
              EffectiveDate: moment(this.selectedEffectiveDate).format(
                "YYYY-MM-DDT00:00:00Z"
              ),
            } as CommitmentActionModel
            if (
              this.commitmentAction.Type ==
                this.actionsEnum.CustomerAddVirtualDesktop ||
              this.commitmentAction.Type ==
                this.actionsEnum.CustomerModifyVirtualDesktop
            ) {
              requestModel.ActionItems?.push({
                Details: {
                  VirtualDesktop: {
                    AdditionalSecurityBL:
                      this.virtualDesktopSpec.AdditionalSecurityBL,
                    AdditionalSecurityNG:
                      this.virtualDesktopSpec.AdditionalSecurityNG,
                    Cpu: Number(
                      Object.values(this.cpuValues)[this.virtualDesktopSpec.Cpu]
                    ),
                    Disk: Number(
                      Object.values(this.diskValues)[
                        this.virtualDesktopSpec.Disk
                      ]
                    ),
                    Ram: Number(
                      Object.values(this.ramValues)[this.virtualDesktopSpec.Ram]
                    ),
                    Level: this.virtualDesktopSpec.Level,
                    Location: this.virtualDesktopSpec.Location,
                    OperatingSystem: this.virtualDesktopSpec.OperatingSystem,
                    Policy: this.virtualDesktopSpec.Policy,
                  } as CommitmentActionVirtualDesktopDetails,
                } as CommitmentActionDetails,
                Type: this.commitmentAction.Type,
              } as CommitmentActionItem)
            }
            if (
              this.commitmentAction.Type ==
              this.actionsEnum.CustomerTerminateVirtualDesktop
            ) {
              requestModel.ActionItems?.push({
                Type: this.commitmentAction.Type,
              } as CommitmentActionItem)
            }
            if (
              this.commitmentAction.Type ==
              this.actionsEnum.CustomerExtendContract
            ) {
              requestModel.ActionItems?.push({
                Details: {
                  ExtendContractDate: moment(this.extendContractDate).format(
                    "YYYY-MM-DDT00:00:00Z"
                  ),
                },
                Type: this.commitmentAction.Type,
              } as CommitmentActionItem)
            }
            if (
              this.commitmentAction.Type ==
              this.actionsEnum.CustomerTerminateContract
            ) {
              requestModel.ActionItems?.push({
                Details: {
                  TerminateContract: {
                    ToCommitmentId:
                      this.terminateContract.SelectedTransferCommitmentId,
                    TransferVirtualDesktop:
                      this.terminateContract.VdState == "Transfer",
                  },
                },
                Type: this.commitmentAction.Type,
              } as CommitmentActionItem)
            }
            const resposne =
              await new ClientCommitmentApi().clientApplyActionToCommitment(
                requestModel
              )
            store.dispatch("showSuccessMessage", "Action applied successfully")
            this.$emit("OnSuccess", resposne)
          } finally {
            this.loading = false
          }
        }
      },
    },
    computed: {
      getMinDate() {
        let today = moment()
        let responseDate = Utils.momentAddBusinessDays(
          today,
          this.commitmentAction.Alt
        )
        if (moment(this.commitment.StartDate) > responseDate) {
          responseDate = moment(this.commitment.StartDate).add(1, "day")
        }
        return responseDate.toDate().toDateString()
      },
      getMaxDate() {
        const beforeCommitmentEndDate = moment(this.commitment.EndDate).add(
          -1,
          "day"
        )
        return beforeCommitmentEndDate.toDate()
      },
      getExtendContractMinDate() {
        const afterEndDate = moment(this.commitment.EndDate).add(1, "day")
        return afterEndDate.toDate()
      },
    },
  })
</script>
<style lang="scss" scoped></style>
