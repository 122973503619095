import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "alert-count" }
const _hoisted_2 = { class: "alert-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, {
    location: "bottom",
    disabled: _ctx.alerts.length == 0,
    "content-class": "alert-container",
    eager: false
  }, {
    activator: _withCtx(({ props }) => [
      _createElementVNode("div", _mergeProps({
        class: [
          _ctx.alerts.length == 0
            ? 'bg-grey-lighten-3'
            : _ctx.type == 'Risk'
            ? 'bg-error'
            : _ctx.type == 'Warning'
            ? 'bg-warning'
            : 'bg-info',
        ]
      }, props, { class: "alert-item" }), [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.alerts.length), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.type), 1)
      ], 16)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (item, index) => {
          return (_openBlock(), _createBlock(_component_v_alert, {
            text: item,
            key: index,
            type: 
          _ctx.type == 'Risk' ? 'error' : _ctx.type == 'Warning' ? 'warning' : 'info'
        ,
            density: "compact",
            class: "alert-message"
          }, null, 8, ["text", "type"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["disabled"]))
}