import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBox = _resolveComponent("SearchBox")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "mx-auto",
    variant: "tonal",
    color: "primary"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { "bg-color": "primary" }, {
        default: _withCtx(() => [
          _createVNode(_component_SearchBox, {
            jobDescription: _ctx.positionJD,
            onSearchByDescription: _ctx.searchByDescription
          }, null, 8, ["jobDescription", "onSearchByDescription"]),
          _createVNode(_component_AutoCompleteField, {
            dense: "",
            label: "Role",
            "item-title": "Name",
            "item-value": "Name",
            items: _ctx.details,
            modelValue: _ctx.searchCriteria.jobRoles,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCriteria.jobRoles) = $event)),
            multiple: "",
            chips: "",
            "closable-chips": "",
            outlined: ""
          }, null, 8, ["items", "modelValue"]),
          _createVNode(_component_AutoCompleteField, {
            dense: "",
            label: "Skills",
            modelValue: _ctx.searchCriteria.skills,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchCriteria.skills) = $event)),
            items: _ctx.skills,
            "item-title": "Text",
            "item-value": "Text",
            multiple: "",
            chips: "",
            "closable-chips": "",
            outlined: ""
          }, {
            item: _withCtx(({ props, item }) => [
              _createVNode(_component_v_list_item, _mergeProps(props, {
                title: item?.raw?.Text,
                value: item?.raw?.id
              }), null, 16, ["title", "value"])
            ]),
            _: 1
          }, 8, ["modelValue", "items"]),
          _createVNode(_component_AutoCompleteField, {
            dense: "",
            label: "Min. Total Experience",
            items: _ctx.experienceYearList,
            modelValue: _ctx.searchCriteria.totalExperience,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchCriteria.totalExperience) = $event)),
            outlined: ""
          }, null, 8, ["items", "modelValue"]),
          _createVNode(_component_AutoCompleteField, {
            dense: "",
            label: "Location",
            items: _ctx.locations,
            multiple: "",
            chips: "",
            "closable-chips": "",
            outlined: "",
            modelValue: _ctx.searchCriteria.locations,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchCriteria.locations) = $event))
          }, {
            item: _withCtx(({ props, item }) => [
              _createVNode(_component_v_list_item, _mergeProps(props, {
                title: item?.raw?.text,
                value: item?.raw?.text
              }), null, 16, ["title", "value"])
            ]),
            _: 1
          }, 8, ["items", "modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}