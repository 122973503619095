<template>
  <div class="item-object">
    <div class="item-details">
      <div class="item-header">
        <div class="item-sub-header">
          <div>
            <Avatar
              :Name="commitment.Name"
              :Photo="commitment.PhotoUrl"
              class="item-avatar"
            />
            <span class="item-name">{{ commitment.Name }}</span>
          </div>
          <div class="item-sub-header">
            <v-tooltip
              location="top"
              v-if="commitment.JobRole && commitment.JobRole != ''"
            >
              <template v-slot:activator="{ props }">
                <v-icon
                  icon="mdi-badge-account-horizontal-outline"
                  v-bind="props"
                />
              </template>
              Job Role:
              <b>{{ commitment.JobRole }}</b>
            </v-tooltip>
            <v-tooltip
              location="top"
              v-if="commitment.JobTitle && commitment.JobTitle != ''"
            >
              <template v-slot:activator="{ props }">
                <v-icon icon="mdi-card-bulleted-outline" v-bind="props" />
              </template>
              Job Title:
              <b>{{ commitment.JobTitle }}</b>
            </v-tooltip>
          </div>
        </div>
        <div class="d-flex text-right">
          <AlertBox
            :commitment="commitment"
            v-if="!commitment.IsCommitmentExpired"
          />
          <v-btn
            @click="openCommitmentMenu(commitment)"
            color="black"
            variant="text"
            size="large"
            icon="mdi-dots-horizontal-circle"
          />
        </div>
      </div>
      <div v-if="commitment.IsCommitmentExpired" class="item-expired">
        The Commitment of this teammember is Expired
      </div>
      <div
        class="chart-container"
        v-if="commitment.VmId && !commitment.IsCommitmentExpired"
      >
        <LineChart :tm="commitment" />
      </div>
      <div
        class="chart-container"
        v-if="
          (commitment.VmId || commitment.CommitmentTimesheetProcessing) &&
          !commitment.IsCommitmentExpired
        "
      >
        <Bar :data="getBarChartData(commitment)" :options="barOptions" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import { TeammemberWithVmDetails } from "shared-components/src/services/openApi/api"
  import Avatar from "@/components/Avatar.vue"
  import { Bar } from "vue-chartjs"
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
  } from "chart.js"
  import moment from "moment"
  import Utils from "shared-components/src/utils/Utils"
  import "chartjs-adapter-date-fns"
  import LineChart from "./LineChart.vue"
  import AlertBox from "./AlertBox.vue"

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale
  )

  export default defineComponent({
    components: {
      Bar,
      LineChart,
      AlertBox,
    },
    props: ["commitment"],
    data() {
      return {
        addLoading: false,
        showNewPosition: false,
        selectedPositionId: null as null | string,
        showPositionDetail: false,
        selectedCommitmentId: null as null | string,
        showCommitmentMenu: false,
        loading: false,
        showProjectModal: false,
        showActivityWatchModal: false,
        showWazuhModal: false,
        showVdSupportModal: false,
        selectedVmId: null as string | null,
        showEpicList: false,
        showExpiredTms: false,

        barOptions: {
          plugins: {
            tooltip: {
              callbacks: {
                label: (context: any) => {
                  if (context.raw) {
                    return `${context.dataset.label}: ${Utils.formatTime(
                      parseFloat(context.raw) * 3600
                    )}`
                  }
                  return ""
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              min: 0,
              max: 16,
              ticks: {
                display: false,
                stepSize: 1,
                autoSkip: false,
              },
            },
            x: {
              ticks: {
                font: {
                  size: 10,
                },
                autoSkip: false,
              },
            },
          },
        },
      }
    },
    async mounted() {},
    methods: {
      openCommitmentMenu(commitment: any) {
        this.$emit("openCommitmentMenu", commitment)
      },
      getBarChartData(commitment: TeammemberWithVmDetails) {
        if (commitment.ActivityWatchDetails) {
          let labels
          const data = commitment.ActivityWatchDetails.map(
            (c: any) => (c.totalSecounds ?? 0) / 3600
          )
          const timesheetData = commitment.TimesheetDetails?.map(
            (c: any) => (c.totalSecounds ?? 0) / 3600
          )
          const chartData = {
            labels: [] as any[],
            datasets: [] as any[],
          }
          if (commitment.VmId) {
            labels = commitment.ActivityWatchDetails.map((c: any) =>
              moment(c.date).format("ddd")
            )
            chartData.labels = labels
            chartData.datasets.push({
              data: data,
              label: `Activity Watch (${commitment.TimeZone})`,
              backgroundColor: "#ffe100",
              stack: "stack 1",
            })
          }
          if (
            commitment.CommitmentTimesheetProcessing &&
            commitment.TimesheetDetails
          ) {
            labels = commitment.TimesheetDetails?.map((c: any) =>
              moment(c.date).format("ddd")
            )
            chartData.labels = labels
            chartData.datasets.push({
              data: timesheetData,
              label: "Timesheet",
              backgroundColor: "#004D99",
              stack: "stack 2",
            })
          }
          return chartData
        }
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/color.scss";
  @import "node_modules/shared-components/assets/style/style.scss";

  .item-expired {
    background-color: $c_platinum !important;
    color: $c_imperial_red;
    text-align: center;
    font-weight: 600;
    padding: 5px;
    @extend .b-r-5;
  }
  .item-object {
    align-items: center;
    background-color: $c_white;
    @extend .b-r-5;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 10px;

    .item-details {
      display: flex;
      flex-direction: column;
      color: $c_black;
      gap: 15px;

      .item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item-sub-header {
          display: flex;
          align-items: center;
          gap: 25px;
        }
      }
    }

    .chart-container {
      padding: 0;
      width: 100% !important;
    }
    .item-avatar {
      box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
    }
    .item-name {
      color: $c_black;
      padding-left: 5px;
    }
  }
</style>
