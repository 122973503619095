import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "header pa-3" }
const _hoisted_2 = { class: "header--title mr-2" }
const _hoisted_3 = { class: "user-name" }
const _hoisted_4 = {
  key: 1,
  class: "body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderNavigationMenu = _resolveComponent("HeaderNavigationMenu")!
  const _component_NotificationDropDown = _resolveComponent("NotificationDropDown")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _directive_closeOnClick = _resolveDirective("closeOnClick")!

  return (_ctx.clientInfo && _ctx.clientInfo.email)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["profile-dropdown mt-2 mr-2", { 'is-open elevation-10': _ctx.profileDropdown }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.TOGGLE_PROFILE_DROPDOWN()), ["prevent"]))
      }, [
        (_ctx.userIsClient || _ctx.userIsPortfolioManager)
          ? (_openBlock(), _createBlock(_component_HeaderNavigationMenu, {
              key: 0,
              class: "navigation-drp"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_NotificationDropDown, {
          userId: _ctx.clientInfo.userId,
          isClient: true,
          class: "notification-drp",
          zIndex: "3000",
          viewAllRoute: `/client/notifications`
        }, null, 8, ["userId"]),
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.clientInfo.fullName), 1)
          ], 512), [
            [_vShow, !_ctx.hideName]
          ]),
          _createVNode(_component_v_icon, {
            color: _ctx.profileDropdown ? 'primary' : 'secondary',
            class: "profile-photo"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" mdi-account-circle ")
            ])),
            _: 1
          }, 8, ["color"])
        ]),
        (_ctx.profileDropdown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.profileDropdown)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: "/client/dashboard",
                    class: "menu-item email pa-3"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.clientInfo.email), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_list, { class: "pt-0 main-menu-items" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item, {
                    to: "/client/dashboard",
                    class: "menu-item"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_content, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode("Dashboard")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_list_item, {
                    to: "/logout",
                    class: "menu-item"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_content, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("Log Out")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ], 2)), [
        [_directive_closeOnClick]
      ])
    : _createCommentVNode("", true)
}