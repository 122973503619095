<template>
  <div>
    <PriceCalculator :commitmentModel="model" :isTotal="true" />
    <v-stepper
      :items="['Virtual Desktop', 'Timesheet Management']"
      v-model="stepState"
    >
      <template v-slot:item.1>
        <v-form ref="frmVD" v-model="vdValid">
          <v-card flat class="mt-2" :loading="loading" :disabled="loading">
            <v-card-title>Virtual Desktop Configuration</v-card-title>
            <v-card-text>
              <VirtualDesktopAddon
                :virtualDesktopSpec="model.VirtualDesktopSpec"
                :diskValues="diskValues"
                :cpuValues="cpuValues"
                :ramValues="ramValues"
                :showEnableCheckBox="false"
                :fullScreen="true"
              />
              <PriceCalculator :commitmentModel="model" />
            </v-card-text>
          </v-card>
          <v-card flat class="mt-2" :loading="loading" :disabled="loading">
            <v-card-title>Team Member details</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <AutoCompleteField
                    label="Job Role"
                    item-title="Name"
                    item-value="id"
                    :rules="[rules.required]"
                    :items="getJobRoles"
                    v-model="model.JobRoleId"
                    outlined
                  />
                </v-col>
                <v-col cols="4">
                  <TextField
                    :rules="[rules.EmailCheck]"
                    label="Email"
                    v-model="model.TmEmail"
                    @focousout="validateTeammember"
                  />
                </v-col>
                <v-col cols="4">
                  <AutoCompleteField
                    :rules="[tmDetailsDisabled || rules.required]"
                    label="Country of Residence"
                    item-title="Name"
                    item-value="Id"
                    :items="countries"
                    v-model="model.TmCountry"
                    :disabled="tmDetailsDisabled"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <TextField
                    :rules="[rules.required]"
                    label="First Name"
                    v-model="model.TmFirstName"
                    :disabled="tmDetailsDisabled"
                  />
                </v-col>
                <v-col cols="4">
                  <TextField
                    :rules="[tmDetailsDisabled || rules.required]"
                    label="Last Name"
                    v-model="model.TmLastName"
                    :disabled="tmDetailsDisabled"
                  />
                </v-col>
                <v-col cols="4">
                  <TextField
                    :disabled="tmDetailsDisabled"
                    :rules="[tmDetailsDisabled || rules.phoneNumber]"
                    label="Mobile Number"
                    v-model="model.TmMobile"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card flat class="mt-2" :loading="loading" :disabled="loading">
            <v-card-title>Engagement Details</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <DateInputField
                    label="Start date"
                    :rules="[rules.required]"
                    first-day-of-week="1"
                    v-model="model.CommitmentStartDate"
                    hide-actions
                    placeholder="Start date"
                    prepend-icon=""
                    :min="getTodayDate"
                  />
                </v-col>
                <v-col cols="4">
                  <DateInputField
                    label="End date"
                    :rules="[rules.required]"
                    first-day-of-week="1"
                    v-model="model.CommitmentEndDate"
                    hide-actions
                    placeholder="End date"
                    prepend-icon=""
                    :min="getMinDate"
                  />
                </v-col>
                <v-col cols="4">
                  <ComboBoxField
                    :rules="[rules.required]"
                    label="Project/Team Name"
                    :items="projects"
                    item-title="Name"
                    item-value="Name"
                    v-model="model.ProjectName"
                    :return-object="false"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </template>

      <template v-slot:item.2>
        <v-card flat :loading="loading" :disabled="loading">
          <v-card-text>
            <v-form ref="frmTimesheet" v-model="timesheetValid">
              <v-row class="align-center">
                <v-col cols="12">
                  <v-checkbox
                    label="Enable Timesheet Management"
                    v-model="model.EnableTimesheetProcessing"
                    @change="EnableTimesheetChange"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <AutoCompleteField
                    :disabled="!model.EnableTimesheetProcessing"
                    :rules="[rules.required]"
                    label="Project Lead"
                    :items="projectLeads"
                    item-title="Name"
                    item-value="id"
                    v-model="model.CommitmentProjectLeadId"
                    append-icon="mdi-plus"
                  />
                </v-col>
                <v-col cols="4">
                  <AutoCompleteField
                    :disabled="!model.EnableTimesheetProcessing || !isClient"
                    :rules="[!isClient || rules.required]"
                    label="Portfolio Manager"
                    :items="portfolioManagers"
                    item-title="Name"
                    item-value="id"
                    v-model="model.CommitmentPortfolioManagerId"
                    append-icon="mdi-plus"
                  />
                </v-col>
                <v-col cols="4">
                  <SelectField
                    :disabled="!model.EnableTimesheetProcessing"
                    :rules="[rules.required]"
                    label="Public Holiday Zone"
                    :items="publicHolidayZones"
                    item-title="Name"
                    item-value="id"
                    v-model="model.CommitmentPublicHolidayZoneId"
                  />
                </v-col>
                <v-col cols="4">
                  <AutoCompleteField
                    :disabled="!model.EnableTimesheetProcessing"
                    :rules="[rules.required]"
                    label="Timezone"
                    v-model="model.CommitmentTimeZone"
                    :items="timeZones"
                    outlined
                    :return-object="false"
                  />
                </v-col>
                <v-col cols="4">
                  <AutoCompleteField
                    :disabled="!model.EnableTimesheetProcessing"
                    :rules="[rules.required]"
                    label="Days of Week"
                    v-model="model.CommitmentWorkingDays"
                    :items="workingDays"
                    multiple
                    outlined
                    @update:modelValue="DayOfWeekChanged"
                    :return-object="false"
                  />
                </v-col>
                <v-col cols="2">
                  <AutoCompleteField
                    :disabled="!model.EnableTimesheetProcessing"
                    :rules="[rules.required]"
                    label="From"
                    v-model="model.CommitmentFrom"
                    :items="hoursInDay"
                  />
                </v-col>
                <v-col cols="2">
                  <AutoCompleteField
                    :disabled="!model.EnableTimesheetProcessing"
                    :rules="[rules.required]"
                    label="To"
                    v-model="model.CommitmentTo"
                    :items="hoursInDay"
                  />
                </v-col>
                <v-col cols="4">
                  <TextField
                    :disabled="
                      !model.EnableTimesheetProcessing ||
                      model.CommitmentHoursPerWeekRadio
                    "
                    label="Expected work hours per week"
                    :rules="[
                      model.CommitmentHoursPerWeekRadio ||
                        rules.minNumber(1) ||
                        rules.required,
                    ]"
                    type="number"
                    v-model="model.CommitmentHoursPerWeek"
                    :min="1"
                  ></TextField>
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    :disabled="!model.EnableTimesheetProcessing"
                    label="Casual"
                    hide-details
                    v-model="model.CommitmentHoursPerWeekRadio"
                    @change="CasualChange"
                  />
                </v-col>
              </v-row>
            </v-form>
            <PriceCalculator
              :commitmentModel="model"
              :isTimesheetScreen="true"
            />
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:item.3>
        <v-card flat>...</v-card>
      </template>

      <template v-slot:actions>
        <v-card-actions class="justify-center">
          <div class="step-actions">
            <v-btn
              class="secondary_btn"
              prependIcon="mdi-arrow-left"
              :disabled="!canBack || loading"
              :loading="loading"
              @click="perviousStep"
            >
              Pervious
            </v-btn>
            <v-btn
              class="primary_btn"
              appendIcon="mdi-arrow-right"
              @click="nextStep"
              :loading="loading"
              :disabled="loading"
              v-if="stepState != 2"
            >
              Next (Timesheet)
            </v-btn>
            <v-btn
              class="primary_btn"
              prependIcon="mdi-content-save"
              @click="createVm"
              :loading="loading"
              :disabled="loading"
            >
              Create
            </v-btn>
          </div>
        </v-card-actions>
      </template>
    </v-stepper>
  </div>
</template>
<script lang="ts">
  import { defineComponent, toRaw } from "vue"
  import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue"
  import rules from "shared-components/src/utils/validations"
  import Details from "shared-components/src/models/Details"
  import DetailsService from "@/services/DetailsService"
  import ProjectService from "@/services/ProjectService"
  import moment from "moment"
  import PortfolioManagerService from "@/services/PortfolioManagerService"
  import store from "@/store"
  import {
    ClientCreateVMRequest,
    ClientTeammemberApi,
    CountryApi,
    CountryModel,
    VirtualDesktopSpec,
  } from "shared-components/src/services/openApi/api"
  import CommitmentService from "@/services/CommitmentService"
  import Project from "shared-components/src/models/Project"
  import PriceCalculator from "./PriceCalculator.vue"

  var momentTz = require("moment-timezone")

  export default defineComponent({
    components: { VirtualDesktopAddon, PriceCalculator },
    data() {
      return {
        lastEmailValidated: "",
        projectLeads: [] as Array<{ id: string; Name: string }>,
        portfolioManagers: [] as Array<{ id: string; Name: string }>,
        publicHolidayZones: [] as Array<{ id: string; Name: string }>,
        projects: [] as Project[],
        stepState: 1,
        vdValid: false,
        timesheetValid: false,
        loading: false,
        rules,
        timeZones: [] as Array<{ title: string; value: string }>,
        tmDetailsDisabled: true,
        countries: [] as CountryModel[],
        ramValues: {
          0: "8",
          1: "16",
          2: "24",
          3: "32",
        },
        cpuValues: {
          0: "2",
          1: "4",
          2: "6",
        },
        diskValues: {
          0: "128",
          1: "256",
          2: "384",
          3: "512",
        },
        details: [] as Details[],
        hoursInDay: ["Flexible"],
        workingDays: [
          { title: "Flexible", value: "Flexible", props: { disabled: false } },
          { title: "SUN", value: "SUN", props: { disabled: false } },
          { title: "MON", value: "MON", props: { disabled: false } },
          { title: "TUE", value: "TUE", props: { disabled: false } },
          { title: "WED", value: "WED", props: { disabled: false } },
          { title: "THR", value: "THR", props: { disabled: false } },
          { title: "FRI", value: "FRI", props: { disabled: false } },
          { title: "SAT", value: "SAT", props: { disabled: false } },
        ],
        model: {
          EnableTimesheetProcessing: false,
          VirtualDesktopSpec: {
            Cpu: 0,
            Ram: 0,
            Disk: 0,
          } as VirtualDesktopSpec,
        } as ClientCreateVMRequest,
      }
    },
    async mounted() {
      await this.loadDetailsList()
      await this.loadProjectLead()
      await this.loadPublicHolidayZone()
      await this.fetchProjects()
      await this.fetchCountries()
      if (this.isClient) {
        await this.loadPortfolioManager()
      }
      this.fillHoursInDay()
      this.loadTimeZones()
    },
    methods: {
      async fetchCountries() {
        this.countries = (await new CountryApi().clientGetCountries()).data
      },
      async validateTeammember() {
        if (
          this.model.TmEmail &&
          this.model.TmEmail != "" &&
          this.lastEmailValidated != this.model.TmEmail
        ) {
          this.model.TmFirstName = ""
          this.model.TmLastName = ""
          this.model.TmCountry = ""
          this.model.TmMobile = ""
          this.lastEmailValidated = this.model.TmEmail
          const isValidEmail = this.rules.Email(this.model.TmEmail)
          if (isValidEmail == "") {
            this.loading = true
            try {
              const response = (
                await new ClientTeammemberApi().clientValidateTeammember(
                  this.model.TmEmail
                )
              ).data as any
              if (response.Id) {
                this.model.TmFirstName = response.FirstName
                this.model.TmLastName = response.LastName
                this.model.TmCountry = response.Country
                this.model.TmMobile = response.Mobile
                this.tmDetailsDisabled = true
              } else {
                this.model.TmFirstName = ""
                this.model.TmLastName = ""
                this.model.TmCountry = ""
                this.model.TmMobile = ""
                this.tmDetailsDisabled = false
              }
            } catch (error: any) {
              this.tmDetailsDisabled = true
            } finally {
              this.loading = false
            }
          }
        }
      },
      async nextStep() {
        let valid = true
        if (this.stepState == 1) {
          const isVdValid = await (this.$refs.frmVD as any).validate()
          valid = isVdValid.valid
        }
        if (valid) {
          this.stepState++
        }
      },
      perviousStep() {
        this.stepState--
      },
      async createVm() {
        let valid = true
        if (this.stepState == 1) {
          const isVdValid = await (this.$refs.frmVD as any).validate()
          valid = isVdValid.valid
        }
        if (this.stepState == 2 && this.model.EnableTimesheetProcessing) {
          const isTmValid = await (this.$refs.frmTimesheet as any).validate()
          valid = isTmValid.valid
        }
        if (valid) {
          this.loading = true
          try {
            const model = JSON.parse(JSON.stringify(this.model))
            if (model.VirtualDesktopSpec) {
              model.VirtualDesktopSpec.Cpu = Number(
                Object.values(this.cpuValues)[model.VirtualDesktopSpec.Cpu ?? 0]
              )
              model.VirtualDesktopSpec.Ram = Number(
                Object.values(this.ramValues)[model.VirtualDesktopSpec.Ram ?? 0]
              )
              model.VirtualDesktopSpec.Disk = Number(
                Object.values(this.diskValues)[
                  model.VirtualDesktopSpec.Disk ?? 0
                ]
              )
            }
            await CommitmentService.createVmAndCommitment(model)
            if (
              this.projects.findIndex(
                (item) => item.Name == model.ProjectName
              ) == -1
            ) {
              this.projects.push({ Name: model.ProjectName } as Project)
            }
            //store.dispatch("showSuccessMessage", "VM request sent successfully");
            this.$router.push({ name: "management" })
          } catch {
            //store.dispatch("showErrorMessage", "There was an issue in sending VM request")
          } finally {
            this.loading = false
          }
        }
      },
      CasualChange() {
        this.model.CommitmentHoursPerWeek = undefined
      },
      EnableTimesheetChange() {
        if (!this.model.EnableTimesheetProcessing) {
          this.model.CommitmentProjectLeadId = undefined
          this.model.CommitmentPortfolioManagerId = undefined
          this.model.CommitmentPublicHolidayZoneId = undefined
          this.model.CommitmentTimeZone = undefined
          this.model.CommitmentWorkingDays = undefined
          this.model.CommitmentFrom = undefined
          this.model.CommitmentTo = undefined
          this.model.CommitmentHoursPerWeek = undefined
          this.model.CommitmentHoursPerWeekRadio = undefined
        }
      },
      fillHoursInDay() {
        this.hoursInDay = ["Flexible"]
        this.hoursInDay = this.hoursInDay.concat(
          ...Array.from(Array(24), (_, hour) => [
            moment({ hour }).format("h:mm A"),
            moment({ hour, minute: 30 }).format("h:mm A"),
          ])
        )
      },
      DayOfWeekChanged() {
        if (this.model.CommitmentWorkingDays?.includes("Flexible")) {
          this.model.CommitmentWorkingDays = ["Flexible"]
          this.workingDays.forEach((item: any) =>
            item.title != "Flexible"
              ? (item.props.disabled = true)
              : (item.props.disabled = false)
          )
        } else {
          this.workingDays.forEach((item: any) => (item.props.disabled = false))
        }
      },
      loadTimeZones() {
        var timeZones = momentTz.tz.names()

        for (var i in timeZones) {
          this.timeZones.push({
            title:
              timeZones[i] +
              " (GMT" +
              momentTz.tz(timeZones[i]).format("Z") +
              ")",
            value: timeZones[i],
          })
        }
      },
      async fetchProjects() {
        this.projects = await ProjectService.getList()
      },
      async loadDetailsList() {
        this.details = await DetailsService.getDetails()
      },
      async loadProjectLead() {
        this.projectLeads = (await ProjectService.getProjectLeadList())
          .filter((c) => c.FirstName || c.LastName)
          .map((item) => {
            return { id: item.id, Name: item.FirstName + " " + item.LastName }
          })
      },
      async loadPortfolioManager() {
        this.portfolioManagers = (
          await PortfolioManagerService.getPortfolioManagerList()
        )
          .filter((c) => c.FirstName || c.LastName)
          .map((item) => {
            return {
              id: item.id ?? "",
              Name: item.FirstName + " " + item.LastName,
            }
          })
      },
      async loadPublicHolidayZone() {
        this.publicHolidayZones = (
          await ProjectService.getPublicHolidayZoneList()
        ).map((item: any) => {
          return { id: item.Id, Name: item.Title }
        })
      },
    },
    computed: {
      getTodayDate() {
        return new Date().toDateString()
      },
      getMinDate() {
        if (
          this.model.CommitmentStartDate &&
          this.model.CommitmentEndDate &&
          this.model.CommitmentStartDate > this.model.CommitmentEndDate
        ) {
          this.model.CommitmentEndDate = this.model.CommitmentStartDate
        }
        return !this.model.CommitmentStartDate ||
          this.model.CommitmentStartDate == ""
          ? this.getTodayDate
          : this.model.CommitmentStartDate
      },
      isClient(): boolean {
        return store.getters.userIsClient
      },
      getJobRoles() {
        return toRaw(this.details).filter((item: any) => item.Type == "JobRole")
      },
      canBack() {
        return this.stepState > 1
      },
    },
  })
</script>
<style scoped lang="scss">
  .step-actions {
    display: flex;
    gap: 5px;
  }
</style>
