import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "actionBtns mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_form, { ref: "mainForm" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { flat: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    (!_ctx.isJdSearch)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TextField, {
                              disabled: "",
                              label: "Team member",
                              modelValue: _ctx.teamMemberName,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.teamMemberName) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.isJdSearch || _ctx.rules.required],
                          label: "Project",
                          items: _ctx.projects,
                          "item-title": "Name",
                          "item-value": "Id",
                          modelValue: _ctx.model.ProjectId,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.ProjectId) = $event)),
                          "append-icon": "mdi-plus"
                        }, null, 8, ["rules", "items", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.isJdSearch || _ctx.rules.required],
                          label: "Project Lead",
                          items: _ctx.projectLeads,
                          "item-title": "Name",
                          "item-value": "id",
                          modelValue: _ctx.model.ProjectLeadId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.ProjectLeadId) = $event)),
                          "append-icon": "mdi-plus"
                        }, null, 8, ["rules", "items", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.isJdSearch || _ctx.rules.required],
                          label: "Portfolio Manager",
                          items: _ctx.portfolioManagers,
                          "item-title": "Name",
                          "item-value": "id",
                          modelValue: _ctx.model.PortfolioManagerId,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.PortfolioManagerId) = $event)),
                          "append-icon": "mdi-plus"
                        }, null, 8, ["rules", "items", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DateInputField, {
                          label: "Start date",
                          rules: [_ctx.isJdSearch || _ctx.rules.required],
                          "first-day-of-week": "1",
                          modelValue: _ctx.model.StartDate,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.StartDate) = $event)),
                          "hide-actions": "",
                          placeholder: "Start date",
                          "prepend-icon": "",
                          min: _ctx.getTodayDate
                        }, null, 8, ["rules", "modelValue", "min"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DateInputField, {
                          label: "End date",
                          rules: [_ctx.isJdSearch || _ctx.rules.required],
                          "first-day-of-week": "1",
                          modelValue: _ctx.model.EndDate,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.EndDate) = $event)),
                          "hide-actions": "",
                          placeholder: "End date",
                          "prepend-icon": "",
                          min: _ctx.getMinDate
                        }, null, 8, ["rules", "modelValue", "min"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "align-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SelectField, {
                          rules: [_ctx.isJdSearch || _ctx.rules.required],
                          label: "Public Holiday Zone",
                          items: _ctx.publicHolidayZones,
                          "item-title": "Name",
                          "item-value": "id",
                          modelValue: _ctx.model.PublicHolidayZoneId,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.PublicHolidayZoneId) = $event))
                        }, null, 8, ["rules", "items", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextField, {
                          label: "Hours per week",
                          rules: [
                  _ctx.isJdSearch ||
                    _ctx.model.HoursPerWeekRadio ||
                    _ctx.rules.minNumber(1) ||
                    _ctx.rules.required,
                ],
                          type: "number",
                          modelValue: _ctx.model.HoursPerWeek,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.HoursPerWeek) = $event)),
                          readonly: _ctx.model.HoursPerWeekRadio,
                          min: 1
                        }, null, 8, ["rules", "modelValue", "readonly"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_checkbox, {
                          label: "Casual",
                          "hide-details": "",
                          modelValue: _ctx.model.HoursPerWeekRadio,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.HoursPerWeekRadio) = $event)),
                          onChange: _ctx.CasualChange
                        }, null, 8, ["modelValue", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_btn, {
        class: "secondary_btn",
        onClick: _ctx.BackStep
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode("Previous")
        ])),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_v_btn, {
        class: "primary_btn_v2",
        onClick: _ctx.NextStep
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createTextVNode("Next")
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}