import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item-object" }
const _hoisted_2 = { class: "item-details" }
const _hoisted_3 = { class: "item-header" }
const _hoisted_4 = { class: "item-sub-header" }
const _hoisted_5 = { class: "item-name" }
const _hoisted_6 = { class: "item-sub-header" }
const _hoisted_7 = { class: "d-flex text-right" }
const _hoisted_8 = {
  key: 0,
  class: "item-expired"
}
const _hoisted_9 = {
  key: 1,
  class: "chart-container"
}
const _hoisted_10 = {
  key: 2,
  class: "chart-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_AlertBox = _resolveComponent("AlertBox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createVNode(_component_Avatar, {
              Name: _ctx.commitment.Name,
              Photo: _ctx.commitment.PhotoUrl,
              class: "item-avatar"
            }, null, 8, ["Name", "Photo"]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.commitment.Name), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.commitment.JobRole && _ctx.commitment.JobRole != '')
              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                  key: 0,
                  location: "top"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_icon, _mergeProps({ icon: "mdi-badge-account-horizontal-outline" }, props), null, 16)
                  ]),
                  default: _withCtx(() => [
                    _cache[1] || (_cache[1] = _createTextVNode(" Job Role: ")),
                    _createElementVNode("b", null, _toDisplayString(_ctx.commitment.JobRole), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.commitment.JobTitle && _ctx.commitment.JobTitle != '')
              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                  key: 1,
                  location: "top"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_icon, _mergeProps({ icon: "mdi-card-bulleted-outline" }, props), null, 16)
                  ]),
                  default: _withCtx(() => [
                    _cache[2] || (_cache[2] = _createTextVNode(" Job Title: ")),
                    _createElementVNode("b", null, _toDisplayString(_ctx.commitment.JobTitle), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (!_ctx.commitment.IsCommitmentExpired)
            ? (_openBlock(), _createBlock(_component_AlertBox, {
                key: 0,
                commitment: _ctx.commitment
              }, null, 8, ["commitment"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_btn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCommitmentMenu(_ctx.commitment))),
            color: "black",
            variant: "text",
            size: "large",
            icon: "mdi-dots-horizontal-circle"
          })
        ])
      ]),
      (_ctx.commitment.IsCommitmentExpired)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, " The Commitment of this teammember is Expired "))
        : _createCommentVNode("", true),
      (_ctx.commitment.VmId && !_ctx.commitment.IsCommitmentExpired)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_LineChart, { tm: _ctx.commitment }, null, 8, ["tm"])
          ]))
        : _createCommentVNode("", true),
      (
          (_ctx.commitment.VmId || _ctx.commitment.CommitmentTimesheetProcessing) &&
          !_ctx.commitment.IsCommitmentExpired
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_Bar, {
              data: _ctx.getBarChartData(_ctx.commitment),
              options: _ctx.barOptions
            }, null, 8, ["data", "options"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}