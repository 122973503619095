<template>
  <div>
    <v-form ref="mainForm">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="4" v-if="!isJdSearch">
              <TextField
                disabled
                label="Team member"
                v-model="teamMemberName"
              />
            </v-col>
            <v-col cols="4">
              <AutoCompleteField
                :rules="[isJdSearch || rules.required]"
                label="Project"
                :items="projects"
                item-title="Name"
                item-value="Id"
                v-model="model.ProjectId"
                append-icon="mdi-plus"
              />
            </v-col>
            <v-col cols="4">
              <AutoCompleteField
                :rules="[isJdSearch || rules.required]"
                label="Project Lead"
                :items="projectLeads"
                item-title="Name"
                item-value="id"
                v-model="model.ProjectLeadId"
                append-icon="mdi-plus"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <AutoCompleteField
                :rules="[isJdSearch || rules.required]"
                label="Portfolio Manager"
                :items="portfolioManagers"
                item-title="Name"
                item-value="id"
                v-model="model.PortfolioManagerId"
                append-icon="mdi-plus"
              />
            </v-col>
            <v-col cols="4">
              <DateInputField
                label="Start date"
                :rules="[isJdSearch || rules.required]"
                first-day-of-week="1"
                v-model="model.StartDate"
                hide-actions
                placeholder="Start date"
                prepend-icon=""
                :min="getTodayDate"
              />
            </v-col>
            <v-col cols="4">
              <DateInputField
                label="End date"
                :rules="[isJdSearch || rules.required]"
                first-day-of-week="1"
                v-model="model.EndDate"
                hide-actions
                placeholder="End date"
                prepend-icon=""
                :min="getMinDate"
              />
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="4">
              <SelectField
                :rules="[isJdSearch || rules.required]"
                label="Public Holiday Zone"
                :items="publicHolidayZones"
                item-title="Name"
                item-value="id"
                v-model="model.PublicHolidayZoneId"
              />
            </v-col>
            <v-col cols="4">
              <TextField
                label="Hours per week"
                :rules="[
                  isJdSearch ||
                    model.HoursPerWeekRadio ||
                    rules.minNumber(1) ||
                    rules.required,
                ]"
                type="number"
                v-model="model.HoursPerWeek"
                :readonly="model.HoursPerWeekRadio"
                :min="1"
              ></TextField>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                label="Casual"
                hide-details
                v-model="model.HoursPerWeekRadio"
                @change="CasualChange"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <div class="actionBtns mt-3">
      <v-btn class="secondary_btn" @click="BackStep">Previous</v-btn>
      <v-btn class="primary_btn_v2" @click="NextStep">Next</v-btn>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import rules from "shared-components/src/utils/validations"

  export default defineComponent({
    props: [
      "selectedProject",
      "projectLeads",
      "portfolioManagers",
      "publicHolidayZones",
      "selectedTM",
      "projects",
      "isJdSearch",
    ],
    data() {
      return {
        rules,
        endDatePickerMenu: false,
        teamMemberName: "",
        model: {
          StartDate: null as Date | null,
          EndDate: null as Date | null,
          HoursPerWeek: null as number | null,
          HoursPerWeekRadio: false,
          PublicHolidayZoneId: null,
          ProjectLeadId: null,
          PortfolioManagerId: null,
          ProjectId: null,
          TeamMemberId: null,
        },
      }
    },
    mounted() {
      this.model.ProjectId = this.selectedProject.Id
      if (!this.isJdSearch) {
        this.model.TeamMemberId = this.selectedTM.Id
        this.teamMemberName = `${this.selectedTM?.FirstName} ${this.selectedTM?.LastName}`
      }
    },
    methods: {
      BackStep() {
        this.$emit("BackStep")
      },
      CasualChange() {
        this.model.HoursPerWeek = null
      },
      async NextStep() {
        const isValid = await (this.$refs.mainForm as any).validate()
        if (isValid.valid) {
          this.$emit("NextStep", this.model)
        }
      },
    },
    computed: {
      getTodayDate() {
        return new Date().toDateString()
      },
      getMinDate() {
        if (
          this.model.StartDate &&
          this.model.EndDate &&
          this.model.StartDate > this.model.EndDate
        ) {
          this.model.EndDate = this.model.StartDate
        }
        return !this.model.StartDate || this.model.StartDate == null
          ? this.getTodayDate
          : this.model.StartDate
      },
    },
    watch: {
      selectedTM: {
        handler(newVal) {
          this.model.TeamMemberId = newVal?.Id
          this.teamMemberName = `${newVal?.FirstName} ${newVal?.LastName}`
        },
        deep: true,
      },
    },
  })
</script>
<style lang="scss" scoped>
  .actionBtns {
    display: flex;
    justify-content: space-between;
  }
</style>
