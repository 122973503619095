import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-title-left" }
const _hoisted_3 = { class: "card-title-right" }
const _hoisted_4 = { class: "item-rows" }
const _hoisted_5 = {
  key: 0,
  class: "no-record"
}
const _hoisted_6 = { class: "item-object" }
const _hoisted_7 = { class: "item-details" }
const _hoisted_8 = { class: "item-header" }
const _hoisted_9 = { class: "item-rows mt-3" }
const _hoisted_10 = { class: "item-header" }
const _hoisted_11 = {
  key: 0,
  class: "no-record"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_ProjectItemTeammember = _resolveComponent("ProjectItemTeammember")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_CommitmentMenu = _resolveComponent("CommitmentMenu")!
  const _component_PositionMenu = _resolveComponent("PositionMenu")!
  const _component_AddOrEditPositionDetails = _resolveComponent("AddOrEditPositionDetails")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_ProjectDetail = _resolveComponent("ProjectDetail")!
  const _component_EpicList = _resolveComponent("EpicList")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { class: "project-item" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.project.Name), 1),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_v_menu, null, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps(props, {
                      variant: "text",
                      size: "small",
                      class: "button",
                      icon: "mdi-dots-vertical"
                    }), null, 16)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item, {
                          title: "Add Position",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewPosition = true))
                        }),
                        _createVNode(_component_v_list_item, {
                          title: "Update Project",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showUpdateProject()))
                        }),
                        _createVNode(_component_v_list_item, {
                          title: "Epic List",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showEpicPopup()))
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_v_divider, { class: "mx-4" }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "item-header" }, [
                _createElementVNode("label", null, "Positions")
              ], -1)),
              (!_ctx.project.Positions || _ctx.project.Positions?.length == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, " There are no any unfilled Positions for this Project "))
                : _createCommentVNode("", true),
              _createVNode(_component_v_row, { class: "m-0" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.Positions, (position) => {
                    return (_openBlock(), _createBlock(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      key: position.Id
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _withDirectives((_openBlock(), _createElementBlock("div", null, [
                                _createVNode(_component_v_icon, { icon: "mdi-badge-account-horizontal-outline" }),
                                _createTextVNode(" " + _toDisplayString(position.JobRoleName), 1)
                              ])), [
                                [_directive_tooltip, 'Job Role', "top"]
                              ]),
                              _createVNode(_component_v_btn, {
                                onClick: ($event: any) => (_ctx.openPositionMenu(position)),
                                color: "black",
                                variant: "text",
                                size: "large",
                                icon: "mdi-dots-horizontal-circle"
                              }, null, 8, ["onClick"])
                            ]),
                            _createElementVNode("div", null, [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _withDirectives((_openBlock(), _createElementBlock("div", null, [
                                        _createVNode(_component_v_icon, { icon: "mdi-card-bulleted-outline" }),
                                        _createTextVNode(" " + _toDisplayString(position.JobTitles ? position.JobTitles : "-"), 1)
                                      ])), [
                                        [_directive_tooltip, 'Job Title', "top"]
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _withDirectives((_openBlock(), _createElementBlock("div", null, [
                                        _createVNode(_component_v_icon, { icon: "mdi-account-file-text-outline" }),
                                        _createTextVNode(" " + _toDisplayString(position.Skills ? position.Skills : "-"), 1)
                                      ])), [
                                        [_directive_tooltip, 'Skills', "top"]
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _createElementVNode("div", null, [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _withDirectives((_openBlock(), _createElementBlock("div", null, [
                                        _createVNode(_component_v_icon, { icon: "mdi-counter" }),
                                        _createTextVNode(" " + _toDisplayString(position.NoOfPositions), 1)
                                      ])), [
                                        [_directive_tooltip, 'Number Of Positions', "top"]
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _withDirectives((_openBlock(), _createElementBlock("div", null, [
                                        _createVNode(_component_v_icon, { icon: "mdi-timer-play" }),
                                        _createTextVNode(" " + _toDisplayString(position.StartDate
                            ? _ctx.formatDate(position.StartDate)
                            : "ASAP"), 1)
                                      ])), [
                                        [_directive_tooltip, 'Start Date', "top"]
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[10] || (_cache[10] = _createElementVNode("label", null, "Teammembers", -1)),
                (!_ctx.notHaveAnyActive(_ctx.project.Teammembers))
                  ? (_openBlock(), _createBlock(_component_v_switch, {
                      key: 0,
                      disabled: _ctx.project.Teammembers?.length == 0,
                      color: "primary",
                      modelValue: _ctx.project.ShowExpiredCommitments,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.project.ShowExpiredCommitments) = $event)),
                      "hide-details": "",
                      label: "Show Expired"
                    }, null, 8, ["disabled", "modelValue"]))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.project.Teammembers || _ctx.project.Teammembers?.length == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, " There are no Teammember for this Project "))
                : _createCommentVNode("", true),
              _createVNode(_component_v_row, { class: "m-0" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ProjectFilteredTmsWithVmChart, (tm) => {
                    return (_openBlock(), _createBlock(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      key: tm.Id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ProjectItemTeammember, {
                          commitment: tm,
                          onOpenCommitmentMenu: _ctx.openCommitmentMenu
                        }, null, 8, ["commitment", "onOpenCommitmentMenu"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "m-0" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ProjectFilteredTmsWithTimesheetChart, (tm) => {
                    return (_openBlock(), _createBlock(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      key: tm.Id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ProjectItemTeammember, {
                          commitment: tm,
                          onOpenCommitmentMenu: _ctx.openCommitmentMenu
                        }, null, 8, ["commitment", "onOpenCommitmentMenu"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "m-0" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ProjectFilteredTmsWithoutChart, (tm) => {
                    return (_openBlock(), _createBlock(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      key: tm.Id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ProjectItemTeammember, {
                          commitment: tm,
                          onOpenCommitmentMenu: _ctx.openCommitmentMenu
                        }, null, 8, ["commitment", "onOpenCommitmentMenu"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "m-0" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ProjectFilteredExpiredTms, (tm) => {
                    return (_openBlock(), _createBlock(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      key: tm.Id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ProjectItemTeammember, {
                          commitment: tm,
                          onOpenCommitmentMenu: _ctx.openCommitmentMenu
                        }, null, 8, ["commitment", "onOpenCommitmentMenu"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.showCommitmentMenu)
      ? (_openBlock(), _createBlock(_component_CommitmentMenu, {
          key: 0,
          project: _ctx.project,
          projectList: _ctx.projectList,
          onOnClose: _ctx.closeCommitmentMenu,
          onOnActionApplied: _ctx.onActionApplied,
          details: _ctx.details
        }, null, 8, ["project", "projectList", "onOnClose", "onOnActionApplied", "details"]))
      : _createCommentVNode("", true),
    (_ctx.showPositionDetail)
      ? (_openBlock(), _createBlock(_component_PositionMenu, {
          key: 1,
          details: _ctx.details,
          skills: _ctx.skills,
          project: _ctx.project,
          projectList: _ctx.projectList,
          projectLeads: _ctx.projectLeads,
          publicHolidayZones: _ctx.publicHolidayZones,
          officeLocations: _ctx.officeLocations,
          lineManagers: _ctx.lineManagers,
          portfolioManagers: _ctx.portfolioManagers,
          officeSpaces: _ctx.officeSpaces,
          onOnClose: _ctx.closePositionMenu
        }, null, 8, ["details", "skills", "project", "projectList", "projectLeads", "publicHolidayZones", "officeLocations", "lineManagers", "portfolioManagers", "officeSpaces", "onOnClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showNewPosition,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showNewPosition) = $event)),
      "max-width": "900"
    }, {
      default: _withCtx(() => [
        (_ctx.showNewPosition)
          ? (_openBlock(), _createBlock(_component_AddOrEditPositionDetails, {
              key: 0,
              isNew: true,
              addLoading: _ctx.addLoading,
              onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showNewPosition = false)),
              onSuccess: _ctx.AddNewProjectPosition,
              details: _ctx.details,
              skills: _ctx.skills
            }, null, 8, ["addLoading", "onSuccess", "details", "skills"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showProjectModal,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showProjectModal) = $event)),
      "max-width": "900"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ProjectDetail, {
          onClose: _ctx.closeProjectPopup,
          onSavedProject: _ctx.savedProject,
          project: _ctx.project
        }, null, 8, ["onClose", "onSavedProject", "project"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showEpicList,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showEpicList) = $event)),
      "max-width": "900"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_EpicList, {
          onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showEpicList = false)),
          project: _ctx.project,
          isClient: true
        }, null, 8, ["project"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}